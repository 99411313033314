import {
  Alpha2Code,
  getAlpha2Codes,
  getName,
  registerLocale
} from "i18n-iso-countries"
import deCountries from "i18n-iso-countries/langs/de.json"
import enCountries from "i18n-iso-countries/langs/en.json"

import { i18n } from "./i18n"

// register all needed languages used by this application
registerLocale(deCountries)
registerLocale(enCountries)

const usel18nCountries = () => {
  const countryNameFromAlpha2 = ({
    isoCountryCode
  }: {
    isoCountryCode: Alpha2Code
  }) => getName(isoCountryCode, i18n.language.substring(0, 2)) as string

  const alpha2CodesToCountries = () =>
    (Object.keys(getAlpha2Codes()) as Alpha2Code[])
      .map((alpha2Country) => [
        alpha2Country,
        countryNameFromAlpha2({
          isoCountryCode: alpha2Country
        })
      ])
      .sort((a, b) => a[1].localeCompare(b[1]))

  const possibleCountries = () =>
    alpha2CodesToCountries().map((alpha2CountryEntry) => ({
      value: alpha2CountryEntry[0],
      text: alpha2CountryEntry[1]
    }))

  return { alpha2CodesToCountries, countryNameFromAlpha2, possibleCountries }
}

export default usel18nCountries
