import { atom } from "jotai"
import { atomWithQuery } from "jotai-tanstack-query"
import { ApiError, get as getClient } from "src/shared/client"
import { userAtom } from "src/shared/stores"

const PAGE_SIZE = 50
const PAGE = 0

export const merchantDpaContractListDataAtom = atomWithQuery<
  MerchantContractListData,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    queryKey: ["merchantDpaContractListData"],
    queryFn: async () => {
      const response = await (
        await getClient({
          path: `/merchant-service/merchants/${user?.merchantId}/contracts?size=${PAGE_SIZE}&page=${PAGE}&type=DPA`
        })
      ).json()

      return response
    },
    enabled: !!user?.merchantId
  }
})

export const downloadMerchantDpaContractAtom = atom<
  (contractId: string, language?: string) => Promise<Blob>
>((get) => async (contractId, language) => {
  const { data: user } = get(userAtom)

  const downloadData = await getClient({
    path: `/merchant-service/merchants/${user?.merchantId}/contracts/${contractId}${language ? `?language=${language}` : ""}`,
    headers: { Accept: "*/*" }
  })

  return downloadData.blob()
})
