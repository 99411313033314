/* eslint-disable simple-import-sort/imports */
import "./shared/i18n"
import "/node_modules/flag-icons/css/flag-icons.min.css"

import { scan } from "react-scan"
import React from "react"
import ReactDOM from "react-dom/client"

import { App, Providers } from "./app"
import { setupCognito } from "./shared/authentication"

scan({
  enabled: true
})

setupCognito()

const reactRootHTMLElement = document.getElementById("root")!
const reactRoot = ReactDOM.createRoot(reactRootHTMLElement)

reactRoot.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
)
