import { useAtom } from "jotai"
import { LoadingContainerSmall } from "src/shared/components"
import { onboardingDocumentDataAtom } from "src/shared/stores"
import {
  AccountDataForm,
  ContactDataForm,
  DocumentsListForm,
  MerchantDataForm,
  OnboardingStepper,
  SummaryForm,
  useValidateOnboarding
} from "src/widgets/onboarding"

const OnboardingStepperContainer = () => {
  const [{ data: uploadedOnboardingDocuments }] = useAtom(
    onboardingDocumentDataAtom
  )
  const { getCurrentStep, getCompletedObject } = useValidateOnboarding()

  if (!uploadedOnboardingDocuments) {
    return <LoadingContainerSmall />
  }

  const currentStep = getCurrentStep(uploadedOnboardingDocuments.length)
  const completedObject = getCompletedObject(currentStep)

  return (
    <OnboardingStepper
      currentStep={completedObject.length === 5 ? 0 : currentStep} // completedObject.length can only be 5 if status is WAITING_FOR_MERCHANT
      completedObject={completedObject}
      uploadedOnboardingDocuments={uploadedOnboardingDocuments}
    >
      <MerchantDataForm />
      <ContactDataForm />
      <AccountDataForm />
      <DocumentsListForm />
      <SummaryForm />
    </OnboardingStepper>
  )
}

export default OnboardingStepperContainer
