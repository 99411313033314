import { Box, CircularProgress } from "@mui/material"

const LoadingContainerSmall = ({ height }: { height?: number }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height={height ? `${height}px` : "100%"}
  >
    <CircularProgress />
  </Box>
)

export default LoadingContainerSmall
