import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import contractTranslationDE from "src/shared/locales/de/contractTranslation.json"
import translationDE from "src/shared/locales/de/translation.json"
import contractTranslationEN from "src/shared/locales/en/contractTranslation.json"
import translationEN from "src/shared/locales/en/translation.json"

const selectedLanguage = localStorage.getItem("selectedLanguage") || "de"

const i18n = i18next.use(LanguageDetector).use(initReactI18next)
i18n.init({
  fallbackLng: selectedLanguage,
  load: "languageOnly",
  keySeparator: ".",
  resources: {
    en: {
      translation: translationEN
    },
    de: {
      translation: translationDE
    }
  },
  detection: {
    lookupLocalStorage: "selectedLanguage",
    convertDetectedLanguage: (language: string) => language.substring(0, 2)
  }
})

const contractI18n = i18next.createInstance().use(LanguageDetector)
contractI18n.init({
  fallbackLng: selectedLanguage,
  load: "languageOnly",
  keySeparator: ".",
  resources: {
    en: {
      contractTranslation: contractTranslationEN
    },
    de: {
      contractTranslation: contractTranslationDE
    }
  },
  detection: {
    lookupLocalStorage: "selectedLanguage",
    convertDetectedLanguage: (language: string) => language.substring(0, 2)
  }
})

export { contractI18n, i18n }
