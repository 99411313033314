// Since the variables are used for setTimeout, they need to be calculated based on how many milliseconds certain amount of minutes has:
// (min * ss(60) * ms(1000))
export const IDLE_TIMEOUT_MILLISECONDS = 15 * 60 * 1000
export const COUNTDOWN_DURATION_MILLISECONDS = 10 * 60 * 1000
export const WARNING_TIMEOUT_MILLISECONDS = 1 * 60 * 1000

export const companyLanguages: Language[] = [
  { lngCode: "de", flagCode: "de", nativeName: "Deutsch" },
  { lngCode: "en", flagCode: "gb", nativeName: "English" }
]

export const contractLanguages: Language[] = [
  { lngCode: "de", flagCode: "de", nativeName: "Deutsch" },
  { lngCode: "en", flagCode: "gb", nativeName: "English" }
]

export const locales: Locales = {
  de: "de-DE",
  en: "en-US"
}

export const passwordStrengthTerms: Record<PasswordStrengthLevel, string> = {
  1: "veryStrong",
  2: "strong",
  3: "medium",
  4: "weak",
  5: "veryWeak"
}

export const passwordConditionStrings: (keyof PasswordConditions)[] = [
  "specialCharacter",
  "number",
  "minCharacters",
  "uppercase",
  "lowercase",
  "whitespace"
]

export const passwordHints: (keyof PasswordStrength)[] = [
  "hasAlphabeticalOrder",
  "hasKeyboardPattern",
  "hasSequentialNumbers"
]
