import { ErrorMessage } from "@hookform/error-message"
import { LocalPhone, MailOutline } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material"
import { useAtom, useAtomValue } from "jotai"
import { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  DepartmentSummaryItem,
  SummaryItem,
  UploadedDocument
} from "src/features/onboarding"
import { ApiError } from "src/shared/client"
import { LoadingContainer } from "src/shared/components"
import {
  filterFormData,
  getCompanyRevenue,
  getCompanySize,
  getDepartmentEmail,
  getDepartmentPhone,
  handlePerson,
  useCustomErrorSnackbar
} from "src/shared/functions"
import { useI18nCountries } from "src/shared/i18n"
import {
  onboardingDataAtom,
  onboardingDocumentDataAtom,
  onboardingIsDpaAcceptedAtom,
  onboardingIsDpaAcceptedErrorAtom,
  onboardingPreviewLatestDpaContractAtom,
  updateOnboardingDataAtom
} from "src/shared/stores"
import { contractLanguages } from "src/shared/variables"

const SummaryForm = () => {
  const { t } = useTranslation("translation")
  const { countryNameFromAlpha2 } = useI18nCountries()
  const { enqueueCustomErrorSnackbar } = useCustomErrorSnackbar()

  const {
    control,
    getValues,
    formState: { errors },
    watch
  } = useFormContext()

  const isSoleProprietorship = watch("isSoleProprietorship")
  const ceoDeputies: Person[] | undefined = getValues("ceoDeputies")

  // Condition for MR and CI render
  const isMandateReferenceAndCreditorIdentifierReady =
    watch("bankAccount.hasDirectDebitMandate") &&
    watch("bankAccount.mandateReference") &&
    watch("bankAccount.creditorIdentifier")

  // Condition for direct debit checkbox call
  const isHasDirectDebitCallPossible =
    !watch("bankAccount.hasDirectDebitMandate") &&
    !watch("bankAccount.mandateReference") &&
    !watch("bankAccount.creditorIdentifier")

  const [
    {
      data: onboardingData,
      refetch: refetchOnboardingData,
      isLoading: isLoadingOnboardingData,
      isError: isErrorFetchingOnboardingData
    }
  ] = useAtom(onboardingDataAtom)
  const [{ mutateAsync: updateOnboardingData }] = useAtom(
    updateOnboardingDataAtom
  )
  const [{ data: uploadedOnboardingDocuments }] = useAtom(
    onboardingDocumentDataAtom
  )
  const [isDpaAccepted, setIsDpaAccepted] = useAtom(onboardingIsDpaAcceptedAtom)
  const [isDpaAcceptedError, setIsDpaAcceptedError] = useAtom(
    onboardingIsDpaAcceptedErrorAtom
  )
  const previewLatestDpaContract = useAtomValue(
    onboardingPreviewLatestDpaContractAtom
  )

  const initialPreviewLoadingState = () =>
    contractLanguages.reduce(
      (
        obj: {
          [key: string]: boolean
        },
        language: Language
      ) => {
        obj[language.lngCode] = false
        return obj
      },
      {}
    )

  const [previewLoadingState, setPreviewLoadingState] = useState<{
    [key: string]: boolean
  }>(initialPreviewLoadingState())

  const updatePreviewLoadingState = (lngCode: string, isLoading: boolean) => {
    setPreviewLoadingState((prevState) => ({
      ...prevState,
      [lngCode]: isLoading
    }))
  }

  const handleConfirmDirectDebit = async (formData: OnboardingRecord) => {
    await updateOnboardingData({
      updatedOnboardingData: filterFormData(formData) as OnboardingRecord
    })
    await refetchOnboardingData()
  }

  const handleClickDpaAcceptanceCheckbox = () => {
    if (!isDpaAccepted) {
      setIsDpaAccepted(true)
      setIsDpaAcceptedError(false)
    } else {
      setIsDpaAccepted(false)
      setIsDpaAcceptedError(true)
    }
  }

  const handlePreviewDpaContract = async (lngCode: string) => {
    try {
      updatePreviewLoadingState(lngCode, true)

      const file = await previewLatestDpaContract(lngCode)
      window.open(URL.createObjectURL(file), "_blank")

      updatePreviewLoadingState(lngCode, false)
    } catch (error) {
      if (error instanceof ApiError) {
        enqueueCustomErrorSnackbar({ error })
      }

      updatePreviewLoadingState(lngCode, false)
    }
  }

  if (isLoadingOnboardingData) {
    return <LoadingContainer />
  }

  if (isErrorFetchingOnboardingData) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100px"
      >
        {t("onboardingPage.summaryFormData.onboardingDataFetchFailureAlert")}
      </Box>
    )
  }

  return (
    <Box data-testid="onboardingSummaryPage">
      {/* COMPANY DATA */}
      <Box className="McpBox" data-testid="sectionCompanyData">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom data-testid="summaryInfoHeader">
            {t(
              isSoleProprietorship
                ? "onboardingPage.summaryFormData.soleProprietorSubtitle"
                : "onboardingPage.summaryFormData.companySubtitle"
            )}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid container item rowSpacing={1} columnSpacing={2} xs={12}>
              {!isSoleProprietorship && (
                <>
                  <Grid item xs={12} md={8} data-testid="summaryCompanyName">
                    <SummaryItem
                      labelName={t("common.companyName")}
                      itemName={getValues("companyName")}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} data-testid="summaryLegalForm">
                    <SummaryItem
                      labelName={t("common.legalForm")}
                      itemName={getValues("legalForm")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SummaryItem
                      labelName={t("common.companyNameAddition")}
                      itemName={getValues("companyNameAddition")}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("common.phone")}
                  itemName={getValues("phone")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("common.homePage")}
                  itemName={getValues("homePage")}
                />
              </Grid>

              <Grid item xs={12} md={9}>
                <SummaryItem
                  labelName={t("common.street")}
                  itemName={getValues("address.street")}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <SummaryItem
                  labelName={t("common.houseNumber")}
                  itemName={getValues("address.houseNumber")}
                />
              </Grid>

              <Grid item xs={12} data-testid="summaryAddressAddition">
                <SummaryItem
                  labelName={t("common.addressAddition")}
                  itemName={getValues("address.addressAddition")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("common.zip")}
                  itemName={getValues("address.zip")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("common.city")}
                  itemName={getValues("address.city")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("common.state")}
                  itemName={getValues("address.state")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Box component="span" />
                <SummaryItem
                  labelName={t("common.country")}
                  itemName={countryNameFromAlpha2({
                    isoCountryCode: getValues("address.country")
                  })}
                  countryFlags={getValues("address.country")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("common.companySize")}
                  itemName={getCompanySize(getValues("companySize"))}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("common.companyRevenue")}
                  itemName={getCompanyRevenue(getValues("companyRevenue"))}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* CEO & CEO DEPUTIES */}
      <Box className="McpBox" data-testid="sectionContactData">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("onboardingPage.summaryFormData.ceosSubtitle")}
          </Typography>
        </Grid>
        <Container>
          <Grid container rowSpacing={2}>
            <Grid container item xs={12} alignItems={"center"}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("common.tooltips.ceo")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <SummaryItem itemName={handlePerson(getValues("ceo"))} />
              </Grid>
            </Grid>

            {ceoDeputies && ceoDeputies.length > 0 && (
              <Grid container item xs={12} alignItems={"center"}>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    {ceoDeputies.length > 1
                      ? t("common.tooltips.ceoDeputies")
                      : t("common.tooltips.ceoDeputy")}
                  </Typography>
                </Grid>
                <Grid container item xs={9} spacing={1}>
                  {ceoDeputies.map((deputy, index) => (
                    <Grid container item xs={12} key={`deputy-${index}`}>
                      <SummaryItem itemName={handlePerson(deputy)} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>

      {/* CONTACT DATA */}
      <Box className="McpBox" data-testid="sectionContactData">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("onboardingPage.summaryFormData.contactSubtitle")}
          </Typography>
        </Grid>
        <Container>
          <Grid container columnSpacing={2} rowSpacing={4}>
            {/* DATA PROTECTOR */}
            <Grid container item xs={12} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("common.tooltips.dataProtectionOfficer")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} lg={4}>
                    <SummaryItem
                      labelName={t("common.externalDataProtectionOfficer")}
                      itemName={
                        getValues("isDataProtectionOfficerExternal")
                          ? t("common.yes")
                          : t("common.no")
                      }
                    />
                  </Grid>
                  <Grid container item xs={12} lg={8}>
                    <Grid item xs={12} lg={12}>
                      <SummaryItem
                        labelName={t(
                          "onboardingPage.summaryFormData.contactSubtitle"
                        )}
                        itemName={handlePerson(
                          getValues("dataProtectionOfficer")
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item columnSpacing={2} xs={12} rowSpacing={1}>
                    <DepartmentSummaryItem
                      labelPhone={t("common.phone")}
                      phone={getValues("dataProtectionOfficer").phone}
                      iconPhone={<LocalPhone />}
                      labelEmail={t("common.email")}
                      email={getValues("dataProtectionOfficer").email}
                      iconEmail={<MailOutline />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* SUPPORT */}
            <Grid container item xs={12} alignItems={"center"} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("onboardingPage.contactFormData.support")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
                  <DepartmentSummaryItem
                    labelPhone={t("common.phone")}
                    phone={getDepartmentPhone(getValues("support"))}
                    iconPhone={<LocalPhone />}
                    labelEmail={t("common.email")}
                    email={getDepartmentEmail(getValues("support"))}
                    iconEmail={<MailOutline />}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* FINANCE */}
            <Grid container item xs={12} alignItems={"center"} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("onboardingPage.contactFormData.finance")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
                  <DepartmentSummaryItem
                    labelPhone={t("common.phone")}
                    phone={getDepartmentPhone(getValues("finance"))}
                    iconPhone={<LocalPhone />}
                    labelEmail={t("common.email")}
                    email={getDepartmentEmail(getValues("finance"))}
                    iconEmail={<MailOutline />}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* DEVELOPMENT */}
            <Grid container item xs={12} alignItems={"center"} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("onboardingPage.contactFormData.development")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
                  <DepartmentSummaryItem
                    labelPhone={t("common.phone")}
                    phone={getDepartmentPhone(getValues("development"))}
                    iconPhone={<LocalPhone />}
                    labelEmail={t("common.email")}
                    email={getDepartmentEmail(getValues("development"))}
                    iconEmail={<MailOutline />}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* TECH SUPPORT */}
            <Grid container item xs={12} alignItems={"center"} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("onboardingPage.contactFormData.techSupport")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
                  <DepartmentSummaryItem
                    labelPhone={t("common.phone")}
                    phone={getDepartmentPhone(getValues("techSupport"))}
                    iconPhone={<LocalPhone />}
                    labelEmail={t("common.email")}
                    email={getDepartmentEmail(getValues("techSupport"))}
                    iconEmail={<MailOutline />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* ACCOUNT DATA */}
      <Box className="McpBox" data-testid="sectionAccountData">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom data-testid="summaryInfoHeader">
            {t("onboardingPage.summaryFormData.accountSubtitle")}
          </Typography>
        </Grid>
        <Container>
          <Grid container item rowSpacing={1} columnSpacing={2} xs={12}>
            <Grid item xs={12}>
              <SummaryItem
                labelName={t("common.accountHolder")}
                itemName={getValues("bankAccount.accountHolder")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SummaryItem
                labelName={t("common.bic")}
                itemName={getValues("bankAccount.bic")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SummaryItem
                labelName={t("common.iban")}
                itemName={getValues("bankAccount.iban")}
              />
            </Grid>

            {getValues("bankAccount.type") === "SEPA" &&
              isMandateReferenceAndCreditorIdentifierReady && (
                <>
                  <Grid item xs={12} md={6}>
                    <SummaryItem
                      labelName={t("common.creditorIdentifier")}
                      itemName={onboardingData?.bankAccount?.creditorIdentifier}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SummaryItem
                      labelName={t("common.mandateReference")}
                      itemName={onboardingData?.bankAccount?.mandateReference}
                    />
                  </Grid>
                </>
              )}
          </Grid>

          {getValues("bankAccount.type") === "NON_SEPA" && (
            <>
              <Grid
                container
                item
                xs={12}
                rowSpacing={2}
                columnSpacing={2}
                alignItems={"flex-start"}
              >
                {/* BENEFICIARY */}
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  data-testid="beneficiaryInfo"
                  mt={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      {t("common.beneficiarySubtitle")}
                    </Typography>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={8}>
                      <SummaryItem
                        labelName={t("common.street")}
                        itemName={getValues("bankAccount.holderAddress.street")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SummaryItem
                        labelName={t("common.houseNumber")}
                        itemName={getValues(
                          "bankAccount.holderAddress.houseNumber"
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={3}>
                      <SummaryItem
                        labelName={t("common.zip")}
                        itemName={getValues("bankAccount.holderAddress.zip")}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <SummaryItem
                        labelName={t("common.city")}
                        itemName={getValues("bankAccount.holderAddress.city")}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    justifyContent={"flex-end"}
                  >
                    <Grid item xs={12}>
                      <SummaryItem
                        labelName={t("common.country")}
                        itemName={countryNameFromAlpha2({
                          isoCountryCode: getValues(
                            "bankAccount.holderAddress.country"
                          )
                        })}
                        countryFlags={getValues(
                          "bankAccount.holderAddress.country"
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* BANK DETAILS */}
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  data-testid="bankDetailsInfo"
                  mt={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      {t("common.bankDetailsSubtitle")}
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid container item xs={6}>
                      <SummaryItem
                        labelName={t("common.bankName")}
                        itemName={getValues("bankAccount.bankAddress.name")}
                      />
                    </Grid>
                    <Grid container item xs={6}>
                      <SummaryItem
                        labelName={t("common.accountNumber")}
                        itemName={getValues("bankAccount.accountNumber")}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={9}>
                      <SummaryItem
                        labelName={t("common.street")}
                        itemName={getValues("bankAccount.bankAddress.street")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SummaryItem
                        labelName={t("common.houseNumber")}
                        itemName={getValues(
                          "bankAccount.bankAddress.houseNumber"
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={3}>
                      <SummaryItem
                        labelName={t("common.zip")}
                        itemName={getValues("bankAccount.bankAddress.zip")}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <SummaryItem
                        labelName={t("common.city")}
                        itemName={getValues("bankAccount.bankAddress.city")}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                      <SummaryItem
                        labelName={t("common.country")}
                        itemName={countryNameFromAlpha2({
                          isoCountryCode: getValues(
                            "bankAccount.bankAddress.country"
                          )
                        })}
                        countryFlags={getValues(
                          "bankAccount.bankAddress.country"
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </Box>

      {/* DOCUMENTS DATA */}
      {(!getValues("isExemptFromTaxFiling") ||
        (getValues("isExemptFromTaxFiling") &&
          uploadedOnboardingDocuments &&
          uploadedOnboardingDocuments.length > 0)) && (
        <Box className="McpBox" data-testid="sectionDocumentsData">
          <Grid item className="McpBoxHeader">
            <Typography
              variant="h2"
              gutterBottom
              data-testid="summaryInfoHeader"
            >
              {getValues("isExemptFromTaxFiling")
                ? t("onboardingPage.summaryFormData.documentsSubtitleNoVat")
                : t("onboardingPage.summaryFormData.documentsSubtitle")}
            </Typography>
          </Grid>
          <Container>
            <Grid item container xs={12} className="McpDocuments" spacing={2}>
              {uploadedOnboardingDocuments?.map((document, index) => (
                <Grid item md={12} lg={6} key={`document-${index}`}>
                  <UploadedDocument
                    document={document}
                    isDeletable={false}
                    isDownloadable={true}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      )}

      <Grid container spacing={2}>
        {/* DIRECT DEBIT MANDATE CHECKBOX */}
        {getValues("bankAccount.type") !== "NON_SEPA" && (
          <Grid container item>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="bankAccount.hasDirectDebitMandate"
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            onChange(e.target.checked)
                            if (isHasDirectDebitCallPossible) {
                              handleConfirmDirectDebit(
                                getValues() as OnboardingRecord
                              )
                            }
                          }}
                          checked={value}
                        />
                      }
                      data-testid="hasDirectDebitMandateCheckbox"
                      label={t(
                        "onboardingPage.accountFormData.hasDirectDebitMandateCheckboxLabel"
                      )}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
          </Grid>
        )}

        {/* DPA CHECKBOX & PREVIEW */}
        <Grid container item spacing={1}>
          <Grid item xs={12}>
            <FormControlLabel
              required
              control={
                <Checkbox
                  required
                  checked={isDpaAccepted}
                  onChange={handleClickDpaAcceptanceCheckbox}
                />
              }
              label={t("onboardingPage.summaryFormData.dpaCheckbox")}
            />
            {isDpaAcceptedError && (
              <Box
                component="span"
                className="McpErrorMessage"
                data-testid="errorMessage"
                role="alert"
              >
                {t(
                  "onboardingPage.summaryFormData.dpaCheckboxRequiredErrorMessage"
                )}
              </Box>
            )}
          </Grid>
          <Grid container item xs={12} gap={1}>
            {contractLanguages.map((lng) => (
              <LoadingButton
                key={lng.lngCode}
                loading={previewLoadingState[lng.lngCode]}
                disabled={Object.values(previewLoadingState).includes(true)}
                variant="outlined"
                onClick={() => handlePreviewDpaContract(lng.lngCode)}
                startIcon={
                  <Box
                    component="span"
                    className={`fi fi-${lng.flagCode} McpFlag`}
                  />
                }
              >
                {t("onboardingPage.summaryFormData.dpaPreviewButton")}
              </LoadingButton>
            ))}
          </Grid>
        </Grid>

        {/* AGB CHECKBOX */}
        <Grid container item>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{
                required: t(
                  "onboardingPage.summaryFormData.agbCheckboxRequiredErrorMessage"
                )
              }}
              name="termsAndConditionsAccepted"
              render={({ field: { onChange } }) => (
                <FormGroup>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        data-testid="agbCheckbox"
                        onChange={onChange}
                        checked={!!getValues("termsAndConditionsAccepted")}
                        required
                      />
                    }
                    label={t("onboardingPage.summaryFormData.agbCheckbox")}
                  />
                </FormGroup>
              )}
            />
            <ErrorMessage
              errors={errors}
              name="termsAndConditionsAccepted"
              render={({ message }) => (
                <Box
                  component="span"
                  className="McpErrorMessage"
                  data-testid="errorMessage"
                  role="alert"
                >
                  {message}
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SummaryForm
