import { useCallback, useMemo } from "react"
import { useFormContext } from "react-hook-form"

const useValidateOnboarding = () => {
  const { watch } = useFormContext()
  const watchedValues = watch()

  const isSoleproprietorshipRequirements = useMemo(
    () => [
      "phone",
      "homePage",
      "address.street",
      "address.zip",
      "address.city",
      "address.country",
      "ceo.title",
      "ceo.firstName",
      "ceo.lastName"
    ],
    []
  )

  const step0Requirements = useMemo(
    () =>
      watchedValues.isSoleProprietorship
        ? isSoleproprietorshipRequirements
        : [...isSoleproprietorshipRequirements, "companyName", "legalForm"],
    [watchedValues.isSoleProprietorship, isSoleproprietorshipRequirements]
  )

  const step1Requirements = useMemo(
    () => [
      "dataProtectionOfficer.title",
      "dataProtectionOfficer.firstName",
      "dataProtectionOfficer.lastName",
      "dataProtectionOfficer.email"
    ],
    []
  )

  const step2Requirements = useMemo(
    () =>
      watchedValues.bankAccount?.type === "NON_SEPA"
        ? [
            "bankAccount.bic",
            "bankAccount.accountHolder",
            "bankAccount.holderAddress.street",
            "bankAccount.holderAddress.zip",
            "bankAccount.holderAddress.city",
            "bankAccount.holderAddress.country",
            "bankAccount.bankAddress.name",
            "bankAccount.accountNumber",
            "bankAccount.bankAddress.street",
            "bankAccount.bankAddress.zip",
            "bankAccount.bankAddress.city",
            "bankAccount.bankAddress.country"
          ]
        : ["bankAccount.bic", "bankAccount.iban", "bankAccount.accountHolder"],
    [watchedValues.bankAccount?.type]
  )

  const step3Requirements = useMemo(() => ["isExemptFromTaxFiling"], [])

  const stepRequirements = useMemo(
    () => [
      step0Requirements,
      step1Requirements,
      step2Requirements,
      step3Requirements
    ],
    [step1Requirements, step2Requirements, step3Requirements, step0Requirements]
  )

  const getCurrentStep = useCallback(
    (documentListLength: number) => {
      // Helper function to check if a step is valid
      const isStepValid = (requirements: string[]) =>
        requirements.every((field) => {
          const value = field
            .split(".")
            .reduce((obj, key) => obj?.[key], watchedValues)
          return Boolean(value)
        })

      // Iterate through steps and return first invalid step
      for (let i = 0; i < stepRequirements.length; i++) {
        if (!isStepValid(stepRequirements[i])) {
          if (i === 3) {
            return documentListLength === 0 ? 3 : 4 // If documents exist, allow proceeding to summary
          }

          return i
        }
      }

      return 4 // If all steps are valid, return summary page
    },
    [watchedValues, stepRequirements]
  )

  const getCompletedObject = useCallback(
    (currentStep: number) =>
      currentStep === 0
        ? []
        : Array.from(
            {
              length:
                watchedValues.status === "WAITING_FOR_MERCHANT"
                  ? currentStep + 1
                  : currentStep
            },
            () => true
          ),
    [watchedValues.status]
  )

  return { getCurrentStep, getCompletedObject }
}

export default useValidateOnboarding
